<template>
  <div class="five-face">
    <breadcrumb
      :title="$route.query.line || $t('main.head.productionLine')"
      :childTitle="$t('dock56Drill.fiveDock')"
      @goBackPage="goBackPage"
    >
      <div>
        <!-- <a-button><a-icon type="form" />导入配置</a-button>
        <a-button><a-icon type="share-alt" />分享配置</a-button> -->
        <a-button @click.native="saveFiveFace"
          ><a-icon type="save" />{{ $t('common.save') }}</a-button
        >
      </div>
    </breadcrumb>
    <div class="wrap">
      <span class="title">{{ $t('dock56Drill.fileType') }}</span>
      <div class="file-format">
        <span>{{ $t('dock56Drill.fileType') }}：</span>
        <a-select default-value="xml" v-model="format56">
          <a-select-option
            :value="item.value"
            v-for="(item, index) in selectList"
            :key="index"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </div>
    </div>
  </div>
</template>

<script>
import breadcrumb from '@/components/topBreadCrumb.vue'

export default {
  components: {
    breadcrumb,
  },
  data() {
    return {
      selectList: [
        { name: 'XML', value: 'xml' },
        { name: 'XML2', value: 'xml2' },
        { name: 'XML3', value: 'xml3' },
        { name: 'MPR', value: 'mpr' },
      ],
      format56: 'xml',
    }
  },
  methods: {
    // 回到设备对接页面
    goBackPage() {
      this.$router.push('/equipment')
    },
    // 保存配置
    saveFiveFace() {
      let obj = {
        data: {
          format56: this.format56,
        },
        setting_id: this.$route.query.setting_id,
      }
      this.$token('/save_dock5F_setting', JSON.stringify(obj), (res) => {
        if (res.status == 1) {
          this.$message({
            message: this.$t('common.saveSuccess'),
            type: 'success',
            duration: 1500,
            offset: 70,
          })
        } else {
          this.$message({
            message: this.$t('lineManager.permissionTip'),
            type: 'info',
            duration: 1500,
            offset: 70,
          })
        }
      })
    },
    // 读取配置
    loadFiveFace() {
      let obj = {
        setting_id: this.$route.query.setting_id,
      }
      this.$token('/load_dock5F_setting', obj, (res) => {
        if (res.status == 1) {
          let flag = true
          this.selectList.forEach((v) => {
            if (v.value == res.data.format56) {
              flag = false
            }
          })
          if (flag) {
            this.format56 = ''
          } else {
            this.format56 = res.data.format56
          }
        } else {
          this.$message({
            message: this.$t('lineManager.permissionTip'),
            type: 'info',
            duration: 1500,
            offset: 70,
          })
        }
      })
    },
  },
  mounted() {
    this.loadFiveFace()
  },
}
</script>

<style scoped lang="less">
.five-face {
  width: 100%;
  height: calc(100vh - 60px);
  .wrap {
    max-width: 1392px;
    margin: 0 auto;
    background: #fff;
    margin-top: 24px;
    .title {
      width: 100%;
      padding: 24px;
      box-sizing: border-box;
      display: inline-block;
      font-size: 18px;
      color: #000;
      font-weight: 600;
      border-bottom: 1px solid #e9e9e9;
    }
    .file-format {
      padding: 24px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .ant-select {
        width: 145px;
      }
    }
  }
}
</style>
